export default [
  {
    header: 'Monitor',
    title: 'monitor',
    icon: 'SearchIcon',
    route: 'pages-knowledge-base',
    resource: 'Brand',
    action: 'manage',
    disabled: true,
    exclude: ['realty']
  }
]