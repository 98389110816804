<template>
  <div>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0" style="color: white !important">
          {{userData ? userData.first_name || userData.first_name : ""}}
        </p>
        <span class="user-status" style="color: white !important">{{userData && userData.main_group !== null ? userData.main_group.name == 'espejo' ? utils.capitalize($t('header.typeUser.personalizedService')) :utils.capitalize(userData.main_group.name) : ""}}</span>
      </div>
      <b-avatar
        v-if="userData"
        size="40"
        :src="userData && userData.profile_image !== null ? getImgProfile(userData.profile_image) : ''"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
      <feather-icon v-if="userData && userData.profile_image === null" icon="UserIcon" size="22" class="text-white" />
      </b-avatar>
    </template>

    <b-dropdown-item>
      <b-row>
        <span class="container-username"> {{userData ? userData.first_name || userData.first_name : ""}} {{userData ? userData.last_name || userData.last_name : "" }}</span>
        <span class="text-muted container-email"> {{userData.email}}</span>
      </b-row>
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item  disabled link-class="d-flex align-items-center"
        v-if="!showStatus() && !checkOutHome()"
      >
        <feather-icon size="16" icon="CreditCardIcon" class="mr-50" />
        <span>{{ $t("header.userMenu.transaction") }}</span>
      </b-dropdown-item>
      <b-dropdown-item  disabled link-class="d-flex align-items-center"
        v-if="!showStatus() && !checkOutHome()"
      >
        <feather-icon size="16" icon="PlusSquareIcon" class="mr-50" />
        <span class="container-submenu">{{ $t("header.userMenu.help") }}</span>
      </b-dropdown-item>
      <b-dropdown-item  disabled link-class="d-flex align-items-center"
        v-if="!showStatus() && !checkOutHome()"
      >
        <feather-icon size="16" icon="MicIcon" class="mr-50" />
        <span class="container-submenu">{{ $t("header.userMenu.suport") }}</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'pages-account-setting', params: { section: 'General' } }"
        link-class="d-flex align-items-center"
      >
        <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
        <span>{{ $t("header.userMenu.settings") }}</span>
      </b-dropdown-item>
  
      <b-dropdown-item link-class="d-flex align-items-center" href="https://brandme.statuspage.io/" target="_blank" v-if="showStatus() && !checkOutHome()">
        <feather-icon
          size="16"
          icon="BarChartIcon"
          class="mr-50"
          variant="light-primary"
        />
        <span>{{$t('header.userMenu.status')}}</span>
      </b-dropdown-item>

      <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
          variant="light-primary"
        />
        <span>{{ $t("header.userMenu.logout") }}</span>
      </b-dropdown-item></b-nav-item-dropdown
    >
     
     </div>
</template>

<script>
import {
  BRow,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useAppConfig from "@core/app-config/useAppConfig";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import { avatar } from "@/assets/images/avatars/usuario.png";
import utils from '@/libs/utils';
import service from "@/services/dashboard";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BRow,
  },
  data() {
    return {
      avatar_file: null,
      utils,
      avatarText,
      avatar,
      check: false,
      domain_path: window.location.host,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  setup() {
    const { isDropdownMenuHidden } = useAppConfig();

    return {
      isDropdownMenuHidden,
    };
  },
  created() {
    this.$root.$on('profile_update', () => {
      this.userData = JSON.parse(localStorage.getItem('userData'));
    });
  },
  methods: {
    getImgProfile(image_url) {
      if (image_url !== null && image_url !== "")
        return service.getCore + image_url;
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");
      localStorage.removeItem("accept_tyc");
      localStorage.removeItem('permissions');

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      // this.$router.push({ name: "auth-login" });
      window.location.replace("/login");
    },
    status() {
      this.$router.push({ name: "status" });
    },
    showStatus() {
      const userData = JSON.parse(localStorage.getItem('userData'));
      if (userData && userData.main_group && userData.main_group.name) return userData.main_group.name !== 'creator';
      return false
    },
    checkOutHome() {
      return this.domain_path.includes('outofhome')
    }
  },
}
</script>
<style>
.container-email {
  padding-left: 15px;
  height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.container-username {
  padding-left: 15px;
  height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.container-submenu {
  height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>

