import ability from '@/libs/acl/ability'

export default [
  {
    header: 'Campañas',
    icon: 'BookmarkIcon',
    title: 'campaigns',
    route: 'campaigns',
    resource: 'Brand',
    action: 'manage',
    disabled: !ability.can('manage', 'view_campaign'),
    permission: 'view_campaign',
  }
]
