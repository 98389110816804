import ability from '@/libs/acl/ability'

export default [
  {
    header: 'Buscador',
    title: 'search',
    icon: 'SearchIcon',
    route: 'auth-search',
    resource: 'Brand',
    action: 'manage',
    disabled: !ability.can('manage', 'view_searcher'),
    permission: 'view_searcher',
    exclude: ['realty']
  },
  {
    header: 'Buscador ubicaciones',
    title: 'search',
    icon: 'SearchIcon',
    route: 'realty-search',
    resource: 'Brand',
    action: 'manage',
    disabled: !ability.can('manage', 'view_searcher'),
    permission: 'view_searcher',
    exclude: ['brand', 'premium', 'whitelabel', 'espejo', 'creator']
  },
  {
    header: 'Maps',
    title: 'map',
    icon: 'MapIcon',
    route: 'realty_maps',
    resource: 'Brand',
    action: 'manage',
    disabled: !ability.can('manage', 'view_searcher'),
    permission: 'view_searcher',
    exclude: ['brand', 'premium', 'whitelabel', 'espejo', 'creator']
  }
]
