/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from '../horizontal/dashboard'
import buscador from '../horizontal/buscador'
import listas from '../horizontal/listas'
import campaigns from '../horizontal/campaigns'
import monitor from '../horizontal/monitor'


// Array of sections
//export default [...dashboard, ...buscador, ...listas]
export default [...dashboard, ...buscador, ...listas, ...campaigns, ...monitor]
