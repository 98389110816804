export default [
  {
    header: 'Dashboards',
    icon: 'HomeIcon',
    title: 'home',
    route: 'dashboard-analytics',
    resource: 'Dashboard',
    action: 'manage',
    newLabel: false,
    exclude: ['realty']
  },
  {
    title: 'campaignsCreator',
    icon: 'BookmarkIcon',
    route: '',
    resource: 'Creator',
    action: 'manage',
    newLabel: false,
    disabled: true,
  },
  {
    title: 'castingCalls',
    icon: 'PhoneCallIcon',
    href: '',
    resource: 'Creator',
    action: 'manage',
    newLabel: false,
    disabled: true,
  },
  {
    title: 'chat',
    icon: 'MessageCircleIcon',
    href: '',
    resource: 'Creator',
    action: 'manage',
    newLabel: false,
    disabled: true,
  },
]
