import ability from '@/libs/acl/ability'

export default [
  {
    header: 'Listas',
    icon: 'ListIcon',
    title: 'list',
    route: 'lists',
    resource: 'Brand',
    action: 'manage',
    disabled: !ability.can('manage', 'view_list'),
    permission: 'view_list',
    exclude: ['realty']
  }
]
